<template>
  <AuthBox :wideBg="true">
    <v-progress-circular v-if="loading" indeterminate color="primary" size="100"></v-progress-circular>
    <div class="py-16" v-if="!loading && !error">
      <h1 class="auth-title indigo--text text--darken-4 mb-10">
        {{ isApprove ? $t('form.approval_process_success') : $t('form.approval_process_denied') }}
      </h1>
      <h3>
        {{ isApprove ? $t('form.request_master_approve') : $t('form.request_master_denied') }}
      </h3>
    </div>
    <div class="py-16" v-if="!loading && error">
      <h1 class="auth-title red--text text--darken-4 mb-10">
        {{ errorMsg }}
      </h1>
    </div>
  </AuthBox>
</template>

<script>
import AuthBox from '@/components/auth/AuthBox';

export default {
  name: 'AuthLogin',
  components: { AuthBox },
  props: {
    type: {
      type: String,
      validator: v => ['approve', 'deny'].includes(v),
      default: 'approve',
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMsg: '',
    };
  },
  computed: {
    isApprove() {
      return this.type === 'approve';
    },
  },
  methods: {
    sendApprovalRequest() {
      const { id } = this.$route.params;
      this.loading = true;
      this.$store
        .dispatch('Account/approvalRequestMaster', {
          id,
          type: this.type,
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(error => {
          this.error = true;
          this.errorMsg = error;
        });
    },
  },
  created() {
    this.sendApprovalRequest();
  },
};
</script>
